<template>
  <section id="preReport">
    <div />
  </section>
</template>
<script>
  export default {
    name: 'SectionHero',
    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        isValid: '',
      }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<style scoped>

</style>
